/**
 * @name: 销售管理-订单管理接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 销售管理-订单管理接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { IOrder, IOrderDelivery, IOrderParams, IOrderRes } from "./types";


export const orderPageApi = (params: IOrderParams) => get<IOrderRes>('/admin/order/query', params)

export const orderDetailApi = (id: string) => get<IOrder>('/admin/order/detail/' + id)

export const orderExportApi = (params: IOrderParams) => get('/admin/order/export', params, 'blob')

export const orderExportDetailApi = (params: IOrderParams) => get('/admin/order/product/export', params, 'blob')

export const orderDeliveryApi = (id: string) => get<IOrderDelivery[]>('/admin/order/getLogisticsList', {id})

export const orderDeliveryDetailApi = (orderDeliveryId: string) => get('/admin/order/queryLogisticsDetail', {orderDeliveryId})
