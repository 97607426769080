
/**
 * @name: 销售管理-订单管理
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 销售管理-订单管理
 * @update: 2024-05-13 11:37
 */
import { Vue, Component } from "vue-property-decorator"
import {
  orderPageApi,
  orderDetailApi,
  orderExportApi,
  orderExportDetailApi,
  orderDeliveryApi,
  orderDeliveryDetailApi
} from '@/apis/sale/order'
import Big from 'big.js'
import {
  IOrder,
  IOrderParams,
  IOrderDelivery
} from '@/apis/sale/order/types'
import { deepCopy, exportFile, getlogisticStatus } from '@/utils/common'
import config from "@/config"
import type { ICrudOption } from "@/types/m-ui-crud"

@Component({})
export default class saleOrder extends Vue {
  config = config
  Big = Big
  getlogisticStatus = getlogisticStatus;
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  // @ts-ignore
  tableData: IOrder[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IOrderParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IOrder> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menuWidth: 100,
    labelWidth: '120px',
    dialogWidth: '1200px',
    viewTitle: '订单详情',
    column: [
      {
        "label": "订单ID",
        "prop": "id",
        "align": "left",
        "width": 180,
        "search": true,
        "slot": true,
        "span": 6
      },
      {
        "label": "渠道来源",
        "prop": "supplyType",
        "align": "center",
        "type": "select",
        "search": true,
        "width": 100,
        "viewHide": true,
        "dicData": [
          {
            label: "京东",
            value: 1
          },
          {
            label: "星链",
            value: 2
          },
          {
            label: '平台测试',
            value: 3
          },
          {
            label: "云商特卖",
            value: 4
          },
          {
            label: "厂商特卖",
            value: 5
          },
          {
            label: "天猫",
            value: 6
          },
          {
            label: "微唯宝特卖",
            value: 7
          },
          {
            label: "云链专供",
            value: 8
          },
          {
            label: "三方仓储",
            value: 9
          },
          {
            label: "华南一仓",
            value: 10
          },
          {
            label: "华南二仓",
            value: 11
          },
          {
            label: "华南二仓",
            value: 12
          },
          {
            label: "3C家电",
            value: 13
          },
          {
            label: "新疆专场",
            value: 14
          },
          {
            label: "西藏专场",
            value: 15
          },
          {
            label: "内蒙专场",
            value: 16
          },
          {
            label: "华东一仓",
            value: 17
          },
          {
            label: "企业专属",
            value: 18
          },
          {
            label: "云采渠道",
            value: 19
          },
          {
            label: "华东二仓",
            value: 20
          },
          {
            label: "华东三仓",
            value: 21
          },
          {
            label: "自营",
            value: 88
          },
        ]
      },
      {
        label: "会员",
        prop: "member",
        search: true,
        hide: true,
        viewHide: true,
        placeholder: "昵称/手机号"
      },
      {
        "label": "昵称",
        "prop": "nickName",
        "align": "center",
        "overHidden": true,
        "span": 6,
        "width": 150
      },
      {
        "label": "手机号",
        "prop": "phone",
        "align": "center",
        "width": 150,
        "span": 6,
        "slot": true,
        "viewSlot": true
      },
      {
        "label": "商品数量",
        "prop": "productQty",
        "hide": true,
        "span": 6,
        "viewSlot": true
      },
      {
        "label": "订单金额（元）",
        "prop": "orderPrice",
        "align": "center",
        "width": 150,
        "span": 6
      },
      {
        "label": "商品金额（元）",
        "prop": "productPrice",
        "align": "center",
        "width": 150,
        "span": 6
      },
      {
        "label": "运费（元）",
        "prop": "freightPrice",
        "align": "center",
        "width": 150,
        "span": 6
      },
      {
        "label": "退款金额（元）",
        "prop": "refundPrice",
        "align": "center",
        "width": 150,
        "span": 6
      },
      {
        "label": "利润（元）",
        "prop": "profitPrice",
        "align": "center",
        "width": 150,
        "viewHide": true
      },
      {
        "label": "支付方式",
        "prop": "payWay",
        "align": "center",
        "type": "select",
        "search": true,
        "width": 100,
        "viewSlot": true,
        "span": 6,
        "dicData": [
          {
            "label": "微信",
            "value": 1
          },
          {
            "label": "支付宝",
            "value": 2
          },
          {
            "label": "贡献值",
            "value": 3
          },
          {
            "label": "余额",
            "value": 4
          },
          {
            "label": "银联支付",
            "value": 5
          }
        ]
      },
      {
        "label": "关联第三方订单ID",
        "prop": "outTradeSn",
        "align": "center",
        search: true,
        "width": 180,
        "viewHide": true
      },
      {
        "label": "订单状态",
        "prop": "status",
        "align": "center",
        "type": "select",
        "search": true,
        "width": 100,
        "span": 6,
        "dicData": [
          {
            "label": "待支付",
            "value": 1
          },
          {
            "label": "待发货",
            "value": 2
          },
          {
            "label": "待收货",
            "value": 3
          },
          {
            "label": "已完成",
            "value": 4
          },
          {
            "label": "已取消",
            "value": 5
          }
        ]
      },
      {
        "label": "下单时间",
        "prop": "orderTime",
        "align": "center",
        "type": "daterange",
        "search": true,
        "width": 150,
        "span": 6
      },
      {
        "label": "完成时间",
        "prop": "finishTime",
        "align": "center",
        "type": "daterange",
        "width": 150,
        "search": true,
        "span": 6
      },
      {
        "label": "付款时间",
        "prop": "payTime",
        "hide": true,
        "span": 12
      },
      {
        "label": "发货时间",
        "prop": "deliveryTime",
        "hide": true,
        "span": 12
      },
      {
        "label": "收货地址",
        "prop": "detailAddress",
        "hide": true,
        "span": 24,
        "viewSlot": true
      },
      {
        "label": "备注",
        "prop": "orderRemarks",
        "hide": true,
        "span": 24,
      },
      {
        "label": "订单取消时间",
        "prop": "cancelTime",
        "hide": true,
        "span": 12,
      },
      {
        "label": "订单取消原因",
        "prop": "cancelReason",
        "hide": true,
        "span": 12,
      }
    ]
  }

  // 订单物流详情弹窗
  deliveryDialog = false
  // 物流详情弹窗
  deliveryDetailDialog = false

  // 物流信息
  logisticsInfo: any = {}

  // 选择的订单对象
  selectOrderRow: Partial<IOrder> = {}

  orderPrice = ''

  refundPrice = ''

  profitPrice = ''

  orderDeliveryList: IOrderDelivery[] = []

  openView (row: IOrder, index: number) {
    orderDetailApi(row.id).then(e => {
      if (e) {
        // @ts-ignore
        this.$refs.crudRef.rowView(e, index)
      }
    })
  }

  getStatusText (row: IOrder) {
    if (row.payWay === 1) {
      return `微信`
    }
    if (row.payWay === 2) {
      return `支付宝`
    }
    if (row.payWay === 3) {
      return `贡献值`
    }
    if (row.payWay === 4) {
      return `余额`
    }
    if (row.payWay === 5) {
      return `银联支付`
    }
  }

  getProductRefundStatusText (status: number) {
    if (status === 1) {
      return '退款中'
    }
    if (status === 2 || status === 4) {
      return '退款成功'
    }
    return '-'
  }

  getSpDataValues (str: string) {
    let json: {key: string, value: string}[];

    try {
      json = JSON.parse(str || '[]')
    } catch (e) {
      json = []
    }

    return json.map(item => item.value).join(';')
  }


  getOrderDetailProductQty(arr: any) {
    if (!arr || !arr.length) {
      return 0
    }
    return arr.reduce((prev: number, cur: any) => {
      return new Big(prev).plus(cur.payQty || 0).toNumber()
    }, 0)
  }


  openDeliveryDialog (row: any) {
    orderDeliveryApi(row.id).then(e => {
      this.orderDeliveryList = e
      this.deliveryDialog = true
    })
  }

  openDeliveryDetailDialog (row: any) {
    this.selectOrderRow = row
    this.logisticsInfo.logisticName = row.logisticName
    this.logisticsInfo.logisticCode = row.logisticCode
    orderDeliveryDetailApi(row.id).then(e => {
      this.logisticsInfo.list = e || []
      this.deliveryDetailDialog = true
    })
  }

  getList () {
    this.tableLoading = true

    const query: IOrderParams = deepCopy(this.queryParam)

    if (query.orderTime && query.orderTime.length) {
      query.orderStartTime = query.orderTime[0]
      query.orderEndTime = query.orderTime[1]
    } else {
      query.orderStartTime = ''
      query.orderEndTime = ''
    }
    delete query.orderTime

    if (query.finishTime && query.finishTime.length) {
      query.finishStartTime = query.finishTime[0]
      query.finishEndTime = query.finishTime[1]
    } else {
      query.finishStartTime = ''
      query.finishEndTime = ''
    }
    delete query.finishTime

    orderPageApi(query).then(e => {
      if (e) {
        this.orderPrice = e.orderPrice
        this.refundPrice = e.refundPrice
        this.profitPrice = e.profitPrice

        this.tableData = e.page.list
        this.tableTotal = e.page.total
        this.tableLoading = false
      }
    })
  }

  exportOrder () {
    const query: IOrderParams = deepCopy(this.queryParam)

    if (query.orderTime && query.orderTime.length) {
      query.orderStartTime = query.orderTime[0]
      query.orderEndTime = query.orderTime[1]
    } else {
      query.orderStartTime = ''
      query.orderEndTime = ''
    }
    delete query.orderTime

    if (query.finishTime && query.finishTime.length) {
      query.finishStartTime = query.finishTime[0]
      query.finishEndTime = query.finishTime[1]
    } else {
      query.finishStartTime = ''
      query.finishEndTime = ''
    }
    delete query.finishTime

    orderExportApi(query).then(e => {
      exportFile(e, '订单.xlsx')
    })
  }

  exportOrderDetail () {
    const query: IOrderParams = deepCopy(this.queryParam)

    if (query.orderTime && query.orderTime.length) {
      query.orderStartTime = query.orderTime[0]
      query.orderEndTime = query.orderTime[1]
    } else {
      query.orderStartTime = ''
      query.orderEndTime = ''
    }
    delete query.orderTime

    if (query.finishTime && query.finishTime.length) {
      query.finishStartTime = query.finishTime[0]
      query.finishEndTime = query.finishTime[1]
    } else {
      query.finishStartTime = ''
      query.finishEndTime = ''
    }
    delete query.finishTime

    orderExportDetailApi(query).then(e => {
      exportFile(e, '商品销售明细.xlsx')
    })
  }

  created () {
    this.getList()
  }
}
